import { Layout, UserContext } from '../components/Layout'
import React from 'react'
import { HeadingLevel } from 'baseui/heading'
import { Heading } from '../components/Heading'
import { Grid, Cell } from 'baseui/layout-grid'
import { HorizontalLine } from '../components/Line'
import { ListNavItem, ListNav } from '../components/ListNav'
import { getSpecialToolsPage, ToolsPagesListResponse } from '../api/cms'
import { useStyletron } from 'baseui'
import { handleErrorFromAPI, loginRedirect } from '../utils'
import { ErrorNotification } from '../components/Notification'

export const ToolsHomeNav = () => {
  const { token, setUserContext } = React.useContext(UserContext)
  const [resourcesId, setResourcesId] = React.useState('')
  const [yourRightsId, setYourRightsId] = React.useState('')
  const [trainingId, setTrainingId] = React.useState('')
  const [loadingErrorActive, setLoadingErrorActive] = React.useState(false)

  React.useEffect(() => {
    if (token) {
      getSpecialToolsPage(token, 'Resources')
        .then((response: ToolsPagesListResponse) => {
          if (response.meta.total_count > 0) {
            setResourcesId(response.items[0].id.toString())
          }
        })
        .catch(error => handleErrorFromAPI(error, setUserContext, setLoadingErrorActive))
      getSpecialToolsPage(token, 'Your Rights')
        .then((response: ToolsPagesListResponse) => {
          if (response.meta.total_count > 0) {
            setYourRightsId(response.items[0].id.toString())
          }
        })
        .catch(error => handleErrorFromAPI(error, setUserContext, setLoadingErrorActive))
      getSpecialToolsPage(token, 'Training')
        .then((response: ToolsPagesListResponse) => {
          if (response.meta.total_count > 0) {
            setTrainingId(response.items[0].id.toString())
          }
        })
        .catch(error => handleErrorFromAPI(error, setUserContext, setLoadingErrorActive))
    } else {
      loginRedirect()
    }
  }, [token, setUserContext])

  return (
    <>
      <ErrorNotification hasError={loadingErrorActive} />
      <ListNav>
        <ListNavItem
          url={'/guides-list'}
          description="Practical how to guides about OHS topics such as PINs, consultation and much more."
        >
          Guides
        </ListNavItem>
        {resourcesId && (
          <ListNavItem
            url={`/tools?id=${resourcesId}`}
            description="Links to useful sites and resources not on OHShelp."
          >
            Resources
          </ListNavItem>
        )}
        {yourRightsId && (
          <ListNavItem
            url={`/tools?id=${yourRightsId}`}
            description="Your legal HSR rights and powers at a glance."
          >
            Your Rights
          </ListNavItem>
        )}
        {trainingId && (
          <ListNavItem
            url={`/tools?id=${trainingId}`}
            description="Links to useful union approved health and safety training."
          >
            Training
          </ListNavItem>
        )}
      </ListNav>
    </>
  )
}

export const ToolsHomePage = () => {
  const [css] = useStyletron()

  return (
    <>
      <Grid>
        <Cell span={[4, 6]}>
          <HeadingLevel>
            <Heading>Tools</Heading>
            <HorizontalLine
              className={css({
                marginBottom: '8px'
              })}
            />
          </HeadingLevel>
        </Cell>
      </Grid>
      <Grid>
        <Cell span={[4, 6]}>
          <ToolsHomeNav />
        </Cell>
      </Grid>
    </>
  )
}

const LayoutToolsHomePage = () => {
  return (
    <Layout showNav={true} searchBarAvailable={true} searchBarOpen={false} backButton={true}>
      <ToolsHomePage />
    </Layout>
  )
}

export default LayoutToolsHomePage
